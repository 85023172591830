/* eslint-disable @next/next/no-img-element */
import { FindHeroImagesDocument } from '@/types/graphql/types'
import { useQuery } from '@apollo/client'
import { SETTINGS_IDS } from 'db'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import Carousel from './HeroSlider/Carousel'

type ImgType = {
  url: string
  hidden: boolean
}[]

//SETTINGS_IDS.heroImages.index    ==> 2

const Hero = () => {
  const { t } = useTranslation('client')
  const { data } = useQuery(FindHeroImagesDocument)

  const heroImages = (data?.findHeroImages?.find(
    item => item?.id === SETTINGS_IDS.heroImages.index,
  )?.value?.indexImages ?? []) as ImgType

  const urls: string[] = heroImages
    .filter(e => e.hidden == false)
    .map(e => e.url)

  return (
    <section>
      <div className="relative">
        <div
          className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-50"
          style={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23164e63' fill-opacity='0.24' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E")`,
          }}
        />
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
            <div className="absolute inset-0" dir="ltr">
              <Carousel
                images={
                  urls.length > 0
                    ? urls
                    : [
                        `https://picsum.photos/800/600?random=1`,
                        `https://picsum.photos/800/600?random=2`,
                      ]
                }
              />
              <div className="absolute inset-0 bg-gradient-to-r from-primary to-secondary mix-blend-multiply" />
            </div>
            <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
              <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                <span className="block text-white">{t('heroTitle')}</span>
                <span className="block text-cyan-100">{t('heroTitleL2')}</span>
              </h1>
              <p className="mt-6 max-w-lg mx-auto text-center text-xl text-cyan-100 sm:max-w-3xl">
                {t('heroSubtitle')}
              </p>
              <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
                  <Link href="/events">
                    <a className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-secondary bg-white hover:bg-indigo-50 sm:px-8">
                      {t('exploreCourses')}
                    </a>
                  </Link>

                  <Link href="/links/contact">
                    <a className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary bg-opacity-60 hover:bg-opacity-70 sm:px-8">
                      {t('contact')}
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="bg-gray-50"
        style={{
          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23164e63' fill-opacity='0.24' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E")`,
        }}
      >
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="mt-6 grid grid-cols-1 gap-8 md:grid-cols-4 lg:grid-cols-4 ">
            <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1 flex-col items-center">
              <img
                className="h-12"
                src="/app-images/cert.png"
                alt={t('accreditedCertificationTitle')}
              />
              <p className="text-xl">{t('accreditedCertificationTitle')}</p>
              <p className="text-sm text-center max-w-xs">
                {t('accreditedCertificationDescription')}
              </p>
            </div>
            <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1 flex-col items-center">
              <img
                className="h-12"
                src="/app-images/SCHS.png"
                alt={t('cmeTitle')}
              />
              <p className="text-xl">{t('cmeTitle')}</p>
              <p className="text-sm text-center max-w-xs">
                {t('cmeDescription')}
              </p>
            </div>
            <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1 flex-col items-center">
              <img
                className="h-12"
                src="/app-images/saudi-heart-association-logo.png"
                alt={t('saudiHeartAssociationTitle')}
              />
              <p className="text-xl">{t('saudiHeartAssociationTitle')}</p>
              <p className="text-sm text-center max-w-xs">
                {t('saudiHeartAssociationDescription')}
              </p>
            </div>
            <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1 flex-col items-center">
              <img
                className="h-12"
                src="/app-images/national-elearning-center-logo.png"
                alt={t('nationalELearningCenterTitle')}
              />
              <p className="text-xl">{t('nationalELearningCenterTitle')}</p>
              <p className="text-sm text-center max-w-xs">
                {t('nationalELearningCenterDescription')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
