import { useMediaQuery } from '@mantine/hooks'
import useTranslation from 'next-translate/useTranslation'
import { useEffect } from 'react'

const Statistics = () => {
  const { t } = useTranslation('client')
  const isMobile = useMediaQuery('(max-width: 1024px)')

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://platform.twitter.com/widgets.js'
    script.async = true
    script.charSet = 'utf-8'
    document.body.appendChild(script)

    return () => {
      // Cleanup the script if the component unmounts
      document.body.removeChild(script)
    }
  }, [])

  return (
    <div className="flex items-center justify-center">
      <section
        className="relative w-full flex items-center justify-center gap-4"
        // style={{
        //   backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23164e63' fill-opacity='0.24' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E")`,
        // }}
      >
        <div
          className="absolute inset-0 h-[300px] bg-gray-50 "
          style={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23164e63' fill-opacity='0.24' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E")`,
            zIndex: -1,
          }}
        />
        <div>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="max-w-4xl mx-auto text-center">
              <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                {t('statisticsTitle')}
              </h2>
              <p className="mt-3 text-xl text-gray-500 sm:mt-4">
                {t('statisticsDescription')}
              </p>
            </div>
          </div>
          <div className="mt-10 pb-12 bg-white sm:pb-16 ">
            <div className="relative">
              <div
                className="absolute inset-0 h-1/2 bg-gray-50"
                style={{
                  backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23164e63' fill-opacity='0.24' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E")`,
                }}
              />
              <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="max-w-4xl mx-auto">
                  <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
                    <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                      <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                        29000+
                      </dt>
                      <dd className="order-1 text-2xl font-extrabold text-secondary">
                        {t('registrations')}
                      </dd>
                    </div>
                    <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                      <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                        150+
                      </dt>
                      <dd className="order-1 text-2xl font-extrabold text-secondary">
                        {t('trainingPrograms')}
                      </dd>
                    </div>
                    <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                      <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                        180+
                      </dt>
                      <dd className="order-1 text-2xl font-extrabold text-secondary">
                        {t('ExpertTrainersTitle')}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
              {isMobile && (
                <div className="relative">
                  <div
                  // className="absolute  top-[25px] h-1/2 bg-gray-50"
                  // style={{
                  //   backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23164e63' fill-opacity='0.24' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E")`,
                  //   zIndex: -1,
                  // }}
                  />
                  <div className="w-[400px] h-[600px] mt-[85px] mx-[auto] overflow-y-scroll ">
                    <iframe
                      title="Twitter Timeline"
                      src="https://syndication.twitter.com/srv/timeline-profile/screen-name/infinity_edu_sa?features=eyJ0ZndfdHdlZXRfZWRpdF9iYWNrZW5kIjp7ImJ1Y2tldCI6Im9uIiwidmVyc2lvbiI6bnVsbH19"
                      style={{
                        border: 'none',
                        overflow: 'hidden',
                        width: '400px',
                        height: '600px',
                      }}
                      frameBorder="0"
                      allowTransparency
                      allowFullScreen
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {!isMobile && (
          <div className="relative">
            <div
              className="absolute  top-[25px] h-1/2 bg-gray-50"
              style={{
                backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23164e63' fill-opacity='0.24' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E")`,
                zIndex: -1,
              }}
            />
            <div className="w-[400px] h-[600px] mt-[-85px] overflow-y-scroll ">
              <iframe
                title="Twitter Timeline"
                src="https://syndication.twitter.com/srv/timeline-profile/screen-name/infinity_edu_sa?features=eyJ0ZndfdHdlZXRfZWRpdF9iYWNrZW5kIjp7ImJ1Y2tldCI6Im9uIiwidmVyc2lvbiI6bnVsbH19"
                style={{
                  border: 'none',
                  overflow: 'hidden',
                  width: '400px',
                  height: '600px',
                }}
                frameBorder="0"
                allowTransparency
                allowFullScreen
              />
            </div>
          </div>
        )}
      </section>
    </div>
  )
}
export default Statistics
